<script lang="ts" setup>
  import { GameTrouvailleListContent } from '~/model/GameTrouvaille/GameTrouvaille'
  import useGameTrouvaille from '~/composables/GameTrouvaille/GameTrouvaille'
  import useTranslation from '~/composables/Translation/Translation'
  import useBem from '~/composables/Bem/Bem'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'

  interface Props extends UseBemProps {
    trouvaille: GameTrouvailleListContent
    showName?: boolean
  }

  const props = withDefaults(defineProps<Props>(), { showName: true })
  const { bemAdd, bemFacets } = useBem('c-reward-item', props, {})
  const { t } = useTranslation()
  const { getTrouvailleIcon } = useGameTrouvaille()

  const trouvailleIcon = computed<string>(() => {
    return getTrouvailleIcon(props.trouvaille)
  })

  const rootClasses = computed<Array<string>>(() => {
    return [...bemFacets.value, bemAdd(`type-${props.trouvaille.trouvailleTypeId}`)]
  })

  const typeLabel = computed<string>(() => {
    return t('reward-box.type-label', {
      type: t(`trouvaille-type.${props.trouvaille.trouvailleTypeId}`),
    })
  })
</script>

<template>
  <div class="c-reward-item" :class="rootClasses">
    <div class="c-reward-item__box">
      <ResponsiveImg
        v-if="trouvailleIcon"
        :src="trouvailleIcon"
        :width="80"
        alt=""
        class="c-reward-item__image"
      />
    </div>
    <span v-if="showName" class="c-reward-item__text">
      <span class="c-reward-item__name">
        {{ trouvaille.name }}
      </span>
      <span class="c-reward-item__type">
        {{ typeLabel }}
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:map';
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/box' as box;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $offset: 2px;

  $trouvaille-types: (
    'special': col.$brand-red,
    'equipment': col.$brand-cyan,
    'junk': col.$monochrome-black,
  );

  .c-reward-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .c-reward-item__box {
    @include box.draw('xx-large');
    margin: $offset 0 0 $offset;
    padding: 2px;
  }

  .c-reward-item__image {
    width: 100%;
    height: 100%;
  }

  .c-reward-item__text {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 20px;
  }

  .c-reward-item__name {
    @include type.h4;
    order: 2;
  }

  .c-reward-item__type {
    @include type.copy-x-small;
    order: 1;
  }

  @each $key, $value in $trouvaille-types {
    .c-reward-item--type-#{$key} {
      .c-reward-item__box {
        @include anaglyph.with-shadow-apply(mono, $offset * -1, $offset * -1, 0, 0, $value);
      }
    }
  }
</style>
