<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { whenever } from '@vueuse/core'
  import { VisualThemeId } from '~/model/VisualTheme/VisualTheme'
  import { OverlayClosePayload, UseOverlayStoreEmits } from '~/model/Overlay/Overlay'
  import { useGameContentStore } from '~/store/GameContent'
  import ContentBlockVideo from '~/components/ContentBlock/ContentBlockVideo/ContentBlockVideo.vue'
  import useMarkdown from '~/composables/Markdown/Markdown'
  import { GameDistrictId } from '~/model/GameDistrict/GameDistrict'
  import { useGameStateStore } from '~/store/GameState'
  import useTranslation from '~/composables/Translation/Translation'
  import { ContentBlockVideoFacet } from '~/components/ContentBlock/ContentBlockVideo/ContentBlockVideoFacet'
  import { ContentBlockQuoteFacet } from '~/components/ContentBlock/ContentBlockQuote/ContentBlockQuoteFacet'

  interface Emits extends UseOverlayStoreEmits {
    (e: 'close', payload: OverlayClosePayload): void
  }

  const emit = defineEmits<Emits>()

  const gameContentStore = useGameContentStore()
  const gameStateStore = useGameStateStore()
  const { t } = useTranslation()
  const { parseMarkdown } = useMarkdown()

  const { districtContent } = storeToRefs(gameContentStore)
  const { visitedDistrictList } = storeToRefs(gameStateStore)

  const requestClose = () => {
    emit('close', {
      transition: 'trs-simple-fade',
    })
  }

  whenever(
    () => districtContent.value,
    () => {
      visitedDistrictList.value.add(districtContent.value?.id as GameDistrictId)
    },
    { immediate: true },
  )
</script>

<template>
  <div class="c-overlay-content-learn-snack u-typography-root">
    <div class="c-overlay-content-learn-snack__box">
      <div class="c-overlay-content-learn-snack__header">
        <h1 class="c-overlay-content-learn-snack__title">
          {{ t('overlay-learn-snack.title', { district: districtContent?.name }) }}
        </h1>
        <BtnClose :visual-theme="VisualThemeId.Negative" @click="requestClose" />
      </div>
      <div class="c-overlay-content-learn-snack__body">
        <div class="s-container s-container--primary">
          <div class="s-container__container">
            <LearnSnack
              :district-content="districtContent"
              class="c-overlay-content-learn-snack__main"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $offset: 8px;
  $margin: $offset + 20px;

  .c-overlay-content-learn-snack {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .c-overlay-content-learn-snack__box {
    @include anaglyph.with-shadow-apply(simple, $offset, $offset, -$offset, -$offset);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: $margin;
    color: col.$variant-cyan-light;
    background-color: col.$monochrome-lead;
  }

  .c-overlay-content-learn-snack__header {
    display: flex;
    padding: 20px;
    justify-content: space-between;
  }

  .c-overlay-content-learn-snack__body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    overflow-x: hidden;
    overflow-y: auto;
  }

  .c-overlay-content-learn-snack__sub-title {
    margin-bottom: 24px;
  }

  .c-overlay-content-learn-snack__grid-el {
    &.c-overlay-content-learn-snack__grid-el--wide {
      grid-column: 2 / span 10;
    }

    &.c-overlay-content-learn-snack__grid-el--narrow {
      grid-column: 3 / span 8;
    }

    &.c-overlay-content-learn-snack__grid-el--col-first {
      grid-column: 2 / span 5;
    }

    &.c-overlay-content-learn-snack__grid-el--col-second {
      grid-column: 7 / span 5;
    }
  }
</style>
