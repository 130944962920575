<script lang="ts" setup>
  import { computed } from 'vue'
  import useBem from '~/composables/Bem/Bem'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import { VisualThemeId } from '~/model/VisualTheme/VisualTheme'
  import { UiIconId, UiIconSizeId } from '~/model/UiIcon/UiIcon'

  interface Props extends UseBemProps {
    facets?: Array<string>
    size?: UiIconSizeId
    visualTheme?: VisualThemeId
  }

  const props = withDefaults(defineProps<Props>(), {
    facets: () => [],
    size: UiIconSizeId.Large,
    visualTheme: VisualThemeId.Positive,
  })
  const { bemAdd, bemFacets } = useBem('c-btn-close', props, {})

  const iconId = computed<UiIconId>(() => {
    switch (props.size) {
      case UiIconSizeId.XLarge:
        return UiIconId.IconControlXLargeClose
      case UiIconSizeId.Large:
        return UiIconId.IconControlLargeClose
      case UiIconSizeId.Medium:
      case UiIconSizeId.Small:
      case UiIconSizeId.XSmall:
        return UiIconId.IconControlMediumClose
    }
  })
  const rootClasses = computed(() => {
    return [bemFacets.value, bemAdd(props.visualTheme), bemAdd(props.size)]
  })
</script>

<template>
  <button :class="rootClasses" class="c-btn-close btn" v-bind="$attrs">
    <UiIcon :id="iconId" :colorize="true" :size="size" />
  </button>
</template>

<style lang="scss" scoped>
  @use 'sass:map';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/transition' as trs;
  @use '/components/Ui/UiIcon/UiIcon' as UiIcon;

  .c-btn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;

    overflow: hidden;
    border: 0 solid transparent;
    text-align: center;

    @each $key, $value in UiIcon.$icon-sizes {
      $size: $value + 4px;

      &.c-btn-close--#{$key} {
        width: $size;
        height: $size;
      }
    }

    &.c-btn-close--visual-theme-negative {
      color: col.$label-text-negative;

      @include utils.has-focus {
        background-color: col.$variant-red-dark;
      }
    }

    &.c-btn-close--visual-theme-positive {
      color: col.$monochrome-lead;

      @include utils.has-focus {
        background-color: col.$brand-cyan;
      }
    }
  }
</style>
