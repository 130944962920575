<script lang="ts" setup>
  import { computed } from 'vue'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import { VisualThemeId } from '~/model/VisualTheme/VisualTheme'

  interface Props extends UseBemProps {
    facets?: Array<string>
    visualTheme?: VisualThemeId
  }

  const props = withDefaults(defineProps<Props>(), {
    facets: () => [],
    visualTheme: VisualThemeId.Positive,
  })
  const { bemAdd, bemFacets } = useBem('c-icon-boxed', props, {})

  const rootClasses = computed(() => {
    return [bemFacets.value, bemAdd(props.visualTheme)]
  })
</script>

<template>
  <div :class="rootClasses" class="c-icon-boxed">
    <div class="c-icon-boxed__box">
      <span class="c-icon-boxed__letter">
        <slot name="letter" />
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:math';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/base/font/font' as font;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;
  @use '/components/Ui/UiIcon/UiIcon' as UiIcon;

  $sizes: (
    'small': (
      size: map-get(UiIcon.$icon-sizes, 'small'),
      offset: 1px,
      padding: 1px,
    ),
    'large': (
      size: map-get(UiIcon.$icon-sizes, 'large'),
      offset: 2px,
      padding: 2px,
    ),
    'xx-large': (
      size: map-get(UiIcon.$icon-sizes, 'xx-large'),
      offset: 3px,
      padding: 2px,
    ),
    'xxx-large': (
      size: map-get(UiIcon.$icon-sizes, 'xxx-large'),
      offset: 4px,
      padding: 4px,
    ),
  );

  .c-icon-boxed__box {
    @include utils.is-selectable(false);
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .c-icon-boxed__letter {
    @include utils.overlay;
    @include font.base-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    line-height: 0;
  }

  .c-icon-boxed--visual-theme-negative {
    color: col.$monochrome-white;

    .c-icon-boxed__box {
      background-color: col.$monochrome-black;
    }
  }

  .c-icon-boxed--visual-theme-positive {
    color: col.$monochrome-black;

    .c-icon-boxed__box {
      background-color: col.$monochrome-white;
    }
  }

  @each $key, $value in $sizes {
    $offset: map-get($value, 'offset');
    $size: map-get($value, 'size');
    $padding: map-get($value, 'padding');

    .c-icon-boxed--#{$key} {
      padding: $offset;

      .c-icon-boxed__box {
        @include anaglyph.with-shadow-apply(simple, $offset, $offset, -$offset, -$offset);
        width: $size - $offset * 2;
        height: $size - $offset * 2;
      }

      .c-icon-boxed__letter {
        font-size: math.div($size - ($offset * 2) - ($padding * 2), 10px) * 1rem;
      }

      &.c-icon-boxed--disabled {
        .c-icon-boxed__box {
          // prettier-ignore
          @include anaglyph.with-shadow-apply(simple, $offset, $offset, -$offset, -$offset, col.$monochrome-magnet, col.$monochrome-steel);
        }
      }

      &.c-icon-boxed--mono-blue {
        .c-icon-boxed__box {
          // prettier-ignore
          @include anaglyph.with-shadow-apply(mono-small, $offset, $offset, -$offset, -$offset, col.$brand-cyan);
        }
      }
    }
  }
</style>
