<script lang="ts" setup>
  import { computed } from 'vue'
  import { storeToRefs } from 'pinia'
  import { onKeyUp } from '@vueuse/core'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import { useOverlayStore } from '~/store/Overlay'
  import { OverlayControl, OverlayId } from '~/model/Overlay/Overlay'
  import { ref, resolveComponent } from '#imports'
  import { useScrollStore } from '~/store/Scroll'

  const BASE_TRANSITION = 'trs-simple-fade'

  interface Props extends UseBemProps {
    facets?: Array<string>
    id: OverlayId
  }

  const props = defineProps<Props>()
  const scrollStore = useScrollStore()
  const overlayStore = useOverlayStore()
  const { closeOverlay, unmountOverlay } = overlayStore
  const { toggleLocked } = scrollStore
  const { hasScrollLockingOverlays, overlayRegistry } = storeToRefs(overlayStore)

  const { bemAdd } = useBem('c-ui-overlay', props, {})

  const hasDisabledScroll = ref<boolean | undefined>(undefined)
  const autoCloseTimeout = ref<number | undefined>(undefined)

  const overlay = computed<OverlayControl | undefined>(() => {
    return overlayRegistry.value[props.id]
  })

  const rootClasses = computed(() => {
    const facets = overlay.value?.facets ?? []
    return facets.map((facet) => bemAdd(facet))
  })

  const overlayComponent = computed<string | undefined>(() => {
    if (!overlay.value?.component) {
      return undefined
    }

    switch (overlay.value.component) {
      case 'OverlayContentLearnSnack':
        return resolveComponent('OverlayContentLearnSnack') as string
      case 'OverlayContentMenu':
        return resolveComponent('OverlayContentMenu') as string
      case 'OverlayContentModal':
        return resolveComponent('OverlayContentModal') as string
      case 'OverlayContentNotification':
        return resolveComponent('OverlayContentNotification') as string
      case 'OverlayContentTaskComplete':
        return resolveComponent('OverlayContentTaskComplete') as string
      default:
        return undefined
    }
  })

  const overlayScrollLockStyles = computed(() => {
    return {}
  })

  const setAutoClose = () => {
    const { autoClose, id } = overlay.value ?? {}
    window.clearTimeout(autoCloseTimeout.value)

    if (!autoClose || !id) {
      return
    }

    const { delay, transition } = autoClose

    autoCloseTimeout.value = window.setTimeout(() => {
      closeOverlay(id, { transition })
    }, delay || 0)
  }

  const onEnterHook = () => {
    const { id, transition, closeWithKey } = overlay.value ?? {}
    if (id && closeWithKey) {
      onKeyUp([closeWithKey], () => {
        closeOverlay(id, {
          transition: transition ?? BASE_TRANSITION,
        })
      })
    }

    if (typeof overlay.value?.disableScroll === 'boolean') {
      hasDisabledScroll.value = overlay.value.disableScroll

      if (hasScrollLockingOverlays.value) {
        toggleLocked(true)
      }
    }
  }

  const onAfterEnterHook = () => {
    setAutoClose()
  }

  const onAfterLeaveHook = () => {
    if (overlay.value && !overlay.value?.isOpen) {
      unmountOverlay(overlay.value.id as OverlayId)
    }

    if (!hasScrollLockingOverlays.value) {
      toggleLocked(false)
    }
  }
</script>

<template>
  <div v-if="overlay" :class="rootClasses" class="c-ui-overlay">
    <Transition
      :name="overlay.transition"
      mode="out-in"
      @enter="onEnterHook"
      @after-enter="onAfterEnterHook"
      @after-leave="onAfterLeaveHook"
    >
      <div v-if="overlay.isOpen" :key="overlay.timestamp" class="c-ui-overlay__root">
        <div class="c-ui-overlay__backdrop" />
        <div class="c-ui-overlay__display">
          <div :style="overlayScrollLockStyles" class="c-ui-overlay__wrap-outer">
            <div class="c-ui-overlay__wrap-inner">
              <button
                class="c-ui-overlay__backdrop-click-area"
                @click.prevent="closeOverlay(id, { transition: overlay.transition })"
              />
              <div class="c-ui-overlay__container">
                <div class="c-ui-overlay__revealer">
                  <component
                    :is="overlayComponent"
                    class="c-ui-overlay__component"
                    v-bind="overlay.props"
                    @close="closeOverlay(id, $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped src="./_UiOverlay.scss" />
