<script lang="ts" setup>
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import { IconBoxedFacet } from '~/components/IconBoxed/IconBoxedFacet'
  import { VisualThemeId } from '~/model/VisualTheme/VisualTheme'
  import {
    ContentBlockQuoteData,
    ContentBlockTypeId,
  } from '~/model/ContentBlockData/ContentBlockData'

  interface Props extends UseBemProps, ContentBlockQuoteData {
    type: ContentBlockTypeId
    facets?: Array<string>
    quote: string
    author: string
    origin?: string
  }

  const props = defineProps<Props>()
  const { bemFacets } = useBem('c-content-block-quote', props, {})

  const figcaption = computed<string>(() => {
    return `${props.author}${props.origin ? ', ' : ''}`
  })
</script>

<template>
  <div :class="bemFacets" class="c-content-block-quote">
    <blockquote class="c-content-block-quote__box">
      <div class="c-content-block-quote__quote">
        <IconBoxed
          :facets="[IconBoxedFacet.Large, IconBoxedFacet.MonoBlue]"
          :visual-theme="VisualThemeId.Negative"
          class="c-content-block-quote__quotation-mark c-content-block-quote__quotation-mark--start"
        >
          <template #letter><span class="c-content-block-quote__letter">«</span></template>
        </IconBoxed>
        <p>
          {{ quote }}
        </p>
        <IconBoxed
          :facets="[IconBoxedFacet.Large, IconBoxedFacet.MonoBlue]"
          :visual-theme="VisualThemeId.Negative"
          class="c-content-block-quote__quotation-mark c-content-block-quote__quotation-mark--end"
        >
          <template #letter><span class="c-content-block-quote__letter">»</span></template>
        </IconBoxed>
      </div>
    </blockquote>
    <figcaption class="c-content-block-quote__source">
      — {{ figcaption }}<cite v-if="origin">{{ origin }}</cite>
    </figcaption>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:math';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $offset: 4px;

  .c-content-block-quote {
    grid-column: 4 / 10;
  }

  .c-content-block-quote__box {
    padding: 2px;
    margin: 0 $offset $offset 0;
    color: col.$monochrome-white;
    background-color: col.$monochrome-black;
  }

  .c-content-block-quote--base {
    .c-content-block-quote__box {
      @include anaglyph.with-shadow-apply(mono, $offset, $offset, 0, 0, col.$brand-cyan);
    }
  }

  .c-content-block-quote--neutral {
    .c-content-block-quote__box {
      @include anaglyph.with-shadow-apply(mono, $offset, $offset, 0, 0, col.$monochrome-white);
    }
  }

  .c-content-block-quote__quote {
    position: relative;
    padding: 24px 40px;
  }

  .c-content-block-quote__quotation-mark {
    position: absolute;
    padding: 1px;

    &.c-content-block-quote__quotation-mark--start {
      top: 0;
      left: 0;
      transform: translate(-40%, -40%);
    }

    &.c-content-block-quote__quotation-mark--end {
      bottom: 0;
      right: 0;
      transform: translate(40%, 40%);
    }
  }

  .c-content-block-quote__letter {
    @include type.h1;
    position: relative;
    transform: scale(1.2) translateY(-0.05em);
  }

  .c-content-block-quote__source {
    @include type.h4;
    padding: 12px 40px;
    text-align: right;
  }
</style>
