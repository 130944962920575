<script lang="ts" setup>
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import { OverlayClosePayload, UseOverlayStoreEmits } from '~/model/Overlay/Overlay'
  import useTranslation from '~/composables/Translation/Translation'
  import useBem from '~/composables/Bem/Bem'

  interface Props extends UseBemProps {
    facets?: Array<string>
    title?: string
    description?: string
  }

  interface Emits extends UseOverlayStoreEmits {
    (e: 'close', payload: OverlayClosePayload): void
  }

  const props = defineProps<Props>()
  const emit = defineEmits<Emits>()
  const { t } = useTranslation()
  const { bemFacets } = useBem<Props>('c-overlay-content-notification', props, {})
</script>

<template>
  <div :class="bemFacets" class="c-overlay-content-notification u-typography-root">
    <div class="c-overlay-content-notification__box">
      <h1 class="u-reset c-overlay-content-notification__title">{{ title }}</h1>
      <p class="u-reset c-overlay-content-notification__description">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;
  @use '/components/NavigationGame/NavigationGame' as NavigationGame;

  $o: 4px;

  .c-overlay-content-notification {
    color: col.$variant-cyan-light;
    background-color: col.$monochrome-lead;
    margin-bottom: NavigationGame.$height + 12px;
    margin-right: 16px;
  }

  .c-overlay-content-notification__box {
    position: relative;
    width: 420px;
    padding: 12px 16px;
  }

  .c-overlay-content-notification__title {
    @include type.h4;
  }

  .c-overlay-content-notification__description {
    @include type.copy-small;
  }

  .c-overlay-content-notification--alert {
    .c-overlay-content-notification__box {
      @include anaglyph.with-shadow-apply(mono, $o, $o, -$o, -$o, $col-1: col.$monochrome-white);
      color: col.$monochrome-white;
      background-color: col.$brand-red;
    }
  }

  .c-overlay-content-notification--info {
    .c-overlay-content-notification__box {
      @include anaglyph.with-shadow-apply(mono, $o, $o, -$o, -$o, $col-1: col.$monochrome-white);
      color: col.$monochrome-white;
      background-color: col.$monochrome-black;
    }
  }

  .c-overlay-content-notification--success {
    .c-overlay-content-notification__box {
      @include anaglyph.with-shadow-apply(mono, $o, $o, -$o, -$o, $col-1: col.$brand-cyan);
      color: col.$monochrome-white;
      background-color: col.$monochrome-black;
    }
  }
</style>
