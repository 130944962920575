<script lang="ts" setup>
  import { computed } from '#imports'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import {
    ContentBlockTypeId,
    ContentBlockVideoData,
  } from '~/model/ContentBlockData/ContentBlockData'

  const VIMEO_RE =
    /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/

  interface Props extends UseBemProps, ContentBlockVideoData {
    type: ContentBlockTypeId
    facets?: Array<string>
    url: string
  }

  const props = defineProps<Props>()
  const { bemFacets } = useBem('c-content-block-video', props, {})

  const embedUrl = computed<string>(() => {
    const r = props.url.match(VIMEO_RE) as Array<string>
    return `https://player.vimeo.com/video/${r[1]}?h=be725acb65&badge=0&autopause=0&player_id=0`
    // return `https://www.youtube-nocookie.com/embed/${r[1]}?color=white&iv_load_policy=3`
  })
</script>

<template>
  <div :class="bemFacets" class="c-content-block-video">
    <div class="c-content-block-video__box">
      <div class="c-content-block-video__outer-wrap">
        <div class="c-content-block-video__inner-wrap">
          <iframe
            :src="embedUrl"
            allow="autoplay; fullscreen"
            allowfullscreen
            class="c-content-block-video__iframe"
            title="Vimeo video player"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:math';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $offset: 4px;
  $aspect-ratio: math.div(16, 9);

  .c-content-block-video {
    grid-column: 4 / 10;
  }

  .c-content-block-video--base {
    .c-content-block-video__box {
      @include anaglyph.with-shadow-apply(mono, $offset, $offset, 0, 0, col.$brand-red);
    }
  }

  .c-content-block-video--neutral {
    .c-content-block-video__box {
      @include anaglyph.with-shadow-apply(mono, $offset, $offset, 0, 0, col.$monochrome-white);
    }
  }

  .c-content-block-video__box {
    padding: 1px;
    margin: 0 $offset $offset 0;
  }

  .c-content-block-video__outer-wrap {
    position: relative;
    width: 100%;
    padding-top: 100% * math.div(1, $aspect-ratio);
  }

  .c-content-block-video__inner-wrap {
    @include utils.overlay();
  }

  .c-content-block-video__iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
  }
</style>
