<script lang="ts" setup>
  import { groupBy } from 'lodash-es'
  import { storeToRefs } from 'pinia'
  import { useGameContentStore } from '~/store/GameContent'
  import { computed } from '#imports'
  import {
    ContentPageId,
    ContentPageListContent,
    MaybePageId,
  } from '~/model/ContentPage/ContentPage'
  import useContentPage from '~/composables/ContentPage/ContentPage'
  import useRouteRecord from '~/composables/RouteRecord/RouteRecord'
  import { MaybeRouteRecordId, RouteRecordId } from '~/model/RouteRecord/RouteRecord'

  const gameContentStore = useGameContentStore()

  const { pageList } = storeToRefs(gameContentStore)
  const { toContentPage } = useContentPage()
  const { isValidId, toRoute } = useRouteRecord()

  const pageGroupList = computed<Record<string, Array<ContentPageListContent>>>(() => {
    return groupBy(pageList.value, ({ group }) => group ?? '__default__')
  })

  const getUrl = (targetId: MaybePageId | MaybeRouteRecordId) => {
    return isValidId(targetId)
      ? toRoute({ name: targetId as RouteRecordId })
      : toContentPage(targetId as ContentPageId)
  }
</script>

<template>
  <div class="c-overlay-content-menu u-typography-root">
    <div class="c-overlay-content-menu__outer">
      <div class="c-overlay-content-menu__inner">
        <ul
          v-for="(group, groupx) in pageGroupList"
          :key="groupx"
          class="c-overlay-content-menu__list u-reset"
        >
          <li
            v-for="{ id, name, menuTitle } in group"
            :key="id"
            class="c-overlay-content-menu__entry"
          >
            <NuxtLink :to="getUrl(id)" class="c-overlay-content-menu__link u-reset">
              {{ menuTitle || name }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/transition' as trs;
  @use '/assets/scss/base/mq' as mq;
  @use '/components/NavigationGame/NavigationGame' as NavigationGame;
  @use '/components/SidebarShell/SidebarShell' as SidebarShell;

  .c-overlay-content-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: auto;

    @include mq.media('<=lg') {
      width: SidebarShell.$width-small;
    }

    @include mq.media('>lg') {
      width: SidebarShell.$width-medium;
    }
  }

  .c-overlay-content-menu__outer {
    padding-top: NavigationGame.$height;
    color: col.$variant-cyan-light;
    background-color: col.$monochrome-lead;
    border-left: 1px solid col.$variant-cyan-light;
    border-bottom: 1px solid col.$variant-cyan-light;
  }

  .c-overlay-content-menu__inner {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    padding: 16px 16px 16px;
  }

  .c-overlay-content-menu__entry ~ .c-overlay-content-menu__entry {
    border-top: 1px solid col.$monochrome-white;
  }

  .c-overlay-content-menu__link {
    @include trs.common-props;
    @include type.h3;
    display: block;
    width: 100%;
    padding: 10px 14px 10px;
    color: col.$variant-cyan-light;

    &.u-router-link-exact-active {
      color: col.$monochrome-quicksilver;
    }

    &:not(.u-router-link-exact-active) {
      @include utils.has-focus {
        color: col.$brand-cyan;
      }
    }
  }
</style>
