<script lang="ts" setup>
  import { ContentBlockVideoFacet } from '~/components/ContentBlock/ContentBlockVideo/ContentBlockVideoFacet'
  import { ContentBlockQuoteFacet } from '~/components/ContentBlock/ContentBlockQuote/ContentBlockQuoteFacet'
  import useTranslation from '~/composables/Translation/Translation'
  import useMarkdown from '~/composables/Markdown/Markdown'
  import { GameDistrictContent } from '~/model/GameDistrict/GameDistrict'
  import { ContentBlockTypeId } from '~/model/ContentBlockData/ContentBlockData'

  interface Props {
    districtContent: GameDistrictContent
  }

  const props = defineProps<Props>()
  const { t } = useTranslation()
  const { parseMarkdown } = useMarkdown()
</script>

<template>
  <main class="c-learn-snack s-grid s-grid--primary">
    <div class="c-learn-snack__grid-el c-learn-snack__grid-el--wide">
      <ContentBlockVideo
        :type="ContentBlockTypeId.LearnSnack"
        v-if="districtContent?.video"
        :facets="[ContentBlockVideoFacet.Neutral]"
        class="c-learn-snack__video"
        v-bind="districtContent?.video"
      />
    </div>
    <div class="c-learn-snack__grid-el c-learn-snack__grid-el--col-first">
      <h2 class="c-learn-snack__title">
        {{ t('overlay-learn-snack.introduction') }}
      </h2>
      <div v-html="parseMarkdown(districtContent?.introduction)" />
    </div>
    <div class="c-learn-snack__grid-el c-learn-snack__grid-el--col-second">
      <h2 class="c-learn-snack__title">
        {{ t('overlay-learn-snack.characteristics') }}
      </h2>
      <div v-html="parseMarkdown(districtContent?.characteristics)" />
    </div>
    <div class="c-learn-snack__grid-el c-learn-snack__grid-el--narrow">
      <ContentBlockQuote
        :type="ContentBlockTypeId.Quote"
        :facets="[ContentBlockQuoteFacet.Neutral]"
        v-bind="districtContent?.blockQuote"
      />
    </div>
  </main>
</template>

<style scoped lang="scss">
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  .c-learn-snack {
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 40px;
    row-gap: 40px;
  }

  .c-learn-snack__title {
    margin-bottom: 24px;
  }

  .c-learn-snack__grid-el {
    &.c-learn-snack__grid-el--wide {
      grid-column: 2 / span 10;
    }

    &.c-learn-snack__grid-el--narrow {
      grid-column: 3 / span 8;
    }

    &.c-learn-snack__grid-el--col-first {
      grid-column: 2 / span 5;
    }

    &.c-learn-snack__grid-el--col-second {
      grid-column: 7 / span 5;
    }
  }
</style>
