<script lang="ts" setup>
  import { computed, useRuntimeConfig } from '#imports'
  import useDebug from '~/composables/Debug/Debug'

  type LoadingStrategy = 'auto' | 'eager' | 'lazy'

  interface RootAttrs {
    srcset: string
    sizes: string
    src: string
  }

  interface Props {
    src: string
    width: number
    loading?: LoadingStrategy
    resolutions?: Array<number>
  }

  // TODO: https://youtrack.jetbrains.com/issue/WEB-54555/Props-Destructure-syntax-in-Vue-script-setup
  // const { src, width, loading = LoadingStrategy.Auto, resolutions = [1, 2] } = defineProps<Props>()
  /* TODO: replace when ready */
  const props = withDefaults(defineProps<Props>(), {
    loading: 'auto',
    resolutions: () => [1, 2],
  })
  const config = useRuntimeConfig()

  const maxResolution = computed(() => Math.max(...props.resolutions))
  const rootAttrs = computed(() => getResponsiveImage(props.src, props.width))

  const getResponsiveImage = (path: string, w: number): RootAttrs => {
    let srcset: string
    let sizes: string
    const src = `/${path.replace(/(^\/)|(\/$)/g, '')}`
    if (config.public.displayDevImages) {
      srcset = ''
    } else {
      srcset = props.resolutions.map((m) => `${src}?nf_resize=fit&w=${w * m} ${w * m}w`).join(', ')
    }

    if (config.public.displayDevImages) {
      const dppx = process.client ? window.devicePixelRatio : 1
      sizes = `${(w / maxResolution.value) * Math.min(maxResolution.value, dppx)}px`
    } else {
      sizes = ''
    }

    return {
      srcset,
      sizes,
      src,
    }
  }
</script>

<template>
  <img :alt="$attrs.alt || ''" :loading="loading" class="c-responsive-img" v-bind="rootAttrs" />
</template>

<style lang="scss" scoped>
  .c-responsive-img {
    overflow: hidden;
  }
</style>
