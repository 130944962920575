<template>
  <component :is="entry.icon" v-if="entry" :class="rootClasses" class="c-ui-icon" />
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import { UiIconEntry, UiIconId, UiIconSizeId } from '~/model/UiIcon/UiIcon'
  import useUiIcon from '~/composables/UiIcon/UiIcon'

  // NOTE: Props extension currently not supported
  // https://vuejs.org/guide/typescript/composition-api.html#typing-component-props
  interface Props extends UseBemProps {
    facets?: Array<string>
    id: UiIconId
    size?: UiIconSizeId
    colorize?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    facets: () => [],
    size: undefined,
    colorize: false,
  })
  const { getEntry } = useUiIcon()
  const { bemAdd, bemFacets } = useBem<Props>('c-ui-icon', props, {})

  const rootClasses = computed<Array<string>>(() => {
    return [...bemFacets.value, bemAdd(props.size ?? ''), bemAdd(props.colorize ? 'colorize' : '')]
  })

  const entry = computed<UiIconEntry | undefined>(() => {
    return getEntry(props.id)
  })
</script>

<style lang="scss" src="./_UiIcon.scss" />
