<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useOverlayStore } from '~/store/Overlay'

  const overlayStore = useOverlayStore()
  const { overlayRegistry } = storeToRefs(overlayStore)
</script>

<template>
  <div class="c-overlay-root">
    <UiOverlay v-for="(overlay, overlayKey) in overlayRegistry" :id="overlayKey" :key="overlayKey" />
  </div>
</template>

<style scoped></style>
