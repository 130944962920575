<template>
  <ShimLayout id="__wrapper" :style="scrollLockStyles">
    <OverlayRoot />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </ShimLayout>
</template>

<script lang="ts" setup>
  import { useHead } from '#imports'
  import ShimLayout from '~/components/ShimLayout/ShimLayout.vue'
  import OverlayRoot from '~/components/OverlayRoot/OverlayRoot.vue'
  import useScrollLock from '~/composables/ScrollLock/ScrollLock'
  import { useBootstrapStore } from '~/store/Bootstrap'

  useBootstrapStore()
  const { scrollLockStyles } = useScrollLock('--scroll-lock')

  useHead({
    htmlAttrs: {
      class: 'scroll-root',
    },
  })
</script>
