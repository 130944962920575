<script lang="ts" setup>
  import useTranslation from '~/composables/Translation/Translation'
  import { GameInteractionId } from '~/model/GameInteraction/GameInteraction'
  import useGameLocation from '~/composables/GameLocation/GameLocation'
  import { GameLocationId } from '~/model/GameLocation/GameLocation'
  import { OverlayClosePayload, UseOverlayStoreEmits } from '~/model/Overlay/Overlay'
  import { navigateTo } from '#imports'

  interface Props {
    locationId: GameLocationId
    interactionId: GameInteractionId
  }

  interface Emits extends UseOverlayStoreEmits {
    (e: 'close', payload: OverlayClosePayload): void
  }

  const props = defineProps<Props>()
  const emit = defineEmits<Emits>()

  const { t } = useTranslation()
  const { toGameLocation } = useGameLocation()

  const close = () => {
    emit('close', { transition: 'trs-simple-fade' })
  }

  const onRequestClose = () => {
    close()
  }

  const onRequestLocation = () => {
    close()
    navigateTo(toGameLocation(props.locationId))
  }
</script>

<template>
  <div class="c-overlay-content-task-complete u-typography-root">
    <div class="s-container s-container--primary">
      <div class="s-container__container">
        <div class="s-grid s-grid--primary">
          <div class="c-overlay-content-task-complete__box">
            <div class="c-overlay-content-task-complete__scroller">
              <h2 class="c-overlay-content-task-complete__title">
                {{ t('overlay-task-complete.title') }}
              </h2>
              <p class="c-overlay-content-task-complete__description">
                {{ t('overlay-task-complete.description') }}
              </p>
              <div class="c-overlay-content-task-complete__rewards">
                <div class="s-grid s-grid--primary">
                  <RewardBox
                    @close="close"
                    :interaction-id="interactionId"
                    :show-actions="true"
                    class="c-overlay-content-task-complete__reward-box"
                  >
                  </RewardBox>
                </div>
              </div>
              <div class="c-overlay-content-task-complete__actions">
                <ul class="c-overlay-content-task-complete__list u-reset">
                  <li class="c-overlay-content-task-complete__entry">
                    <button class="btn btn--primary btn--large" @click="onRequestClose">
                      {{ t('overlay-task-complete.action-back-label') }}
                    </button>
                  </li>
                  <li class="c-overlay-content-task-complete__entry">
                    <button class="btn btn--primary btn--large" @click="onRequestLocation">
                      {{ t('overlay-task-complete.action-to-location-label') }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $offset: 8px;
  $margin: $offset + 20px;

  .c-overlay-content-task-complete__box {
    @include anaglyph.with-shadow-apply(simple, $offset, $offset, -$offset, -$offset);
    grid-column: 2 / 12;
    padding: 32px;
    margin: $margin;
    overflow: hidden;
    color: col.$variant-cyan-light;
    background-color: col.$monochrome-lead;
  }

  .c-overlay-content-task-complete__scroller {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .c-overlay-content-task-complete__description {
    margin-top: 8px;
  }

  .c-overlay-content-task-complete__rewards {
    margin-top: 40px;
  }

  .c-overlay-content-task-complete__reward-box {
    grid-column: 2 / 12;
  }

  .c-overlay-content-task-complete__actions {
    margin-top: 52px;
  }

  .c-overlay-content-task-complete__list {
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    column-gap: 32px;
  }
</style>
