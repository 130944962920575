<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useLocaleStore } from '~/store/Locale'
  import { useGameContentStore } from '~/store/GameContent'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import { GameInteractionId } from '~/model/GameInteraction/GameInteraction'
  import useTranslation from '~/composables/Translation/Translation'
  import RewardItem from '~/components/RewardItem/RewardItem.vue'
  import useRouteRecord from '~/composables/RouteRecord/RouteRecord'
  import { RouteRecordId } from '~/model/RouteRecord/RouteRecord'

  interface Props extends UseBemProps {
    interactionId: GameInteractionId
    showActions?: boolean
  }

  interface Emits {
    (e: 'close'): void
  }

  const localeStore = useLocaleStore()
  const gameContentStore = useGameContentStore()

  const props = withDefaults(defineProps<Props>(), {
    showActions: false,
  })
  const emit = defineEmits<Emits>()
  const { t } = useTranslation()
  const { toRoute } = useRouteRecord()
  const { rewardList } = storeToRefs(gameContentStore)
  const { localeId } = storeToRefs(localeStore)
</script>

<template>
  <div class="c-reward-box">
    <div class="c-reward-box__box">
      <div class="c-reward-box__head">
        <div class="c-reward-box__symbol">B</div>
        <h2 class="c-reward-box__title">
          {{ t('reward-box.title') }}
        </h2>
      </div>
      <div v-if="$slots.description" class="c-reward-box__description">
        <slot name="description" />
      </div>
      <ul v-if="rewardList" class="c-reward-box__reward-list u-reset">
        <li
          v-for="rewardEntry in rewardList"
          :key="rewardEntry.id"
          class="c-reward-box__reward-entry"
        >
          <RewardItem :trouvaille="rewardEntry" />
        </li>
      </ul>
      <div v-if="showActions" class="c-reward-box__actions">
        <NuxtLink
          :to="toRoute({ name: RouteRecordId.GameCinemaRoom })"
          class="btn btn--small btn--primary"
          @click="emit('close')"
        >
          {{ t('reward-box.action-to-cinema-label') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:map';
  @use 'sass:math';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/box' as box;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $icon-offset: 2px;
  $box-offset: 4px;

  .c-reward-box__box {
    @include anaglyph.with-shadow-apply(mono, $box-offset, $box-offset, 0, 0, col.$brand-cyan);
    position: relative;
    width: 100%;
    padding: 32px;
    margin: 0 $box-offset $box-offset 0;
    color: col.$monochrome-black;
    background-color: col.$monochrome-white;
  }

  .c-reward-box__head {
    display: flex;
    flex-flow: row nowrap;
  }

  .c-reward-box__symbol {
    @include box.draw('large');
    @include anaglyph.with-shadow-apply(
      mono,
      $icon-offset * -1,
      $icon-offset * -1,
      0,
      0,
      col.$brand-cyan
    );
    @include type.h2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.07em;
    margin: $icon-offset 0 0 $icon-offset;
  }

  .c-reward-box__title {
    margin-left: 12px;
  }

  .c-reward-box__description {
    margin-top: 24px;
  }

  .c-reward-box__reward-list {
    display: flex;
    flex-flow: row wrap;
    margin-top: 24px;
    column-gap: 44px;
    row-gap: 32px;
  }

  .c-reward-box__actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
</style>
