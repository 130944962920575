<script lang="ts" setup>
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import useTranslation from '~/composables/Translation/Translation'
  import { computed } from '#imports'
  import BtnClose from '~/components/BtnClose/BtnClose.vue'
  import { OverlayContentModalAction } from '~/components/OverlayContent/OverlayContentModal/OverlayContentModalAction'
  import { OverlayClosePayload, UseOverlayStoreEmits } from '~/model/Overlay/Overlay'
  import { VisualThemeId } from '~/model/VisualTheme/VisualTheme'

  interface Props extends UseBemProps {
    facets?: Array<string>
    title: string
    description: string
    actions: Array<OverlayContentModalAction>
  }

  interface Emits extends UseOverlayStoreEmits {
    (e: 'close', payload: OverlayClosePayload): void
  }

  const props = defineProps<Props>()
  const emit = defineEmits<Emits>()
  const { t } = useTranslation()
  const { bemFacets, bemAdd } = useBem<Props>('c-overlay-content-modal', props, {})

  const requestClose = () => {
    emit('close', {
      transition: 'trs-simple-fade',
    })
  }

  const getBtnFacets = (btnFacets: Array<string>) => {
    return btnFacets.map((btnFacet) => bemAdd(btnFacet, undefined, 'btn'))
  }

  // NOTE: This helper method is strangely needed to make it work
  const handleCallback = (callback: () => void) => {
    callback()
  }
</script>

<template>
  <div :class="bemFacets" class="c-overlay-content-modal u-typography-root">
    <div class="c-overlay-content-modal__box">
      <BtnClose
        :visual-theme="VisualThemeId.Negative"
        class="c-overlay-content-modal__btn-close"
        @click="requestClose"
      />
      <h1 class="c-overlay-content-modal__title">
        {{ title }}
      </h1>
      <p class="c-overlay-content-modal__description u-reset">
        {{ description }}
      </p>
      <div v-if="actions.length" class="c-overlay-content-modal__actions">
        <ul class="c-overlay-content-modal__list u-reset">
          <li
            v-for="({ btnFacets, callback, label }, actionIdx) in actions"
            :key="actionIdx"
            class="c-overlay-content-modal__entry"
          >
            <button :class="getBtnFacets(btnFacets)" class="btn" @click="handleCallback(callback)">
              {{ label }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $o: 4px;

  .c-overlay-content-modal {
    margin: 0 $o $o 0;
  }

  .c-overlay-content-modal__box {
    position: relative;
    min-width: 420px;
    max-width: 600px;
    padding: 32px 40px;

    @include mq.media('<=sm') {
      min-width: unset;
      width: 100%;
      margin: 12px;
    }
  }

  .c-overlay-content-modal__btn-close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: col.$monochrome-white;
  }

  .c-overlay-content-modal__title {
    margin-bottom: 16px;
  }

  .c-overlay-content-modal__description {
    @include type.h4;
  }

  .c-overlay-content-modal__actions {
    margin-top: 20px;
  }

  .c-overlay-content-modal__list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
    margin: 0px 4px 4px 0;
  }

  .c-overlay-content-modal--alert {
    .c-overlay-content-modal__box {
      @include anaglyph.with-shadow-apply(mono, $o, $o, -$o, -$o, $col-1: col.$monochrome-white);
      color: col.$monochrome-white;
      background-color: col.$brand-red;
    }
  }

  .c-overlay-content-modal--info {
    .c-overlay-content-modal__box {
      @include anaglyph.with-shadow-apply(mono, $o, $o, -$o, -$o, $col-1: col.$monochrome-white);
      color: col.$monochrome-white;
      background-color: col.$monochrome-black;
    }
  }
</style>
